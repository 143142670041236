@tailwind base;
@tailwind components;
@tailwind utilities;
/***************
GOOGLE FONT
*****************/
@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,300;0,500;1,300;1,500&display=swap');
/**************  
VARIABLES
***************/
$default:#b7786e;
$accent:#ffc709;
$lightAccent:#fff8b7;
$grey:#C4C4C4;
$lightGrey:#EBEBEB;
$darkGrey:#2E2E2E;
$headFont:  'Libre Caslon Text', serif;
$defaultFont: 'Montserrat', sans-serif;

h1,h2,h3,h4,h5,h6 {
    font-family:$headFont;
    text-transform:uppercase;
    line-height:1.6;
    color:$default;
}
body,p,li,a {
    font-family:$defaultFont;
    font-weight:300;
    color:$default;
}
body {
 background:url('/background.jpg');
 background-size:cover;
 background-position:center top;
 background-repeat:no-repeat;
}
#result-text {
    &.invalid {
        color:red;
        font-family:$defaultFont;
    }
}
